<template>
  <vp-base-layout
    page-title="입금하기"
    page-default-back-link="/">
    <vp-template-list
      :datas="datas"
      :total="pageInfo.total"
      detail-icon="false">
      <template #top>
        <ion-list lines="none">
          <ion-item>
            <ion-label>입금대상</ion-label>
            <ion-text
              slot="end"
              color="primary">
              {{ $filters.countTxt(cnt1) }}
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label>입금금액</ion-label>
            <ion-text
              slot="end"
              color="primary">
              {{ $filters.currencyWON(totalPrice) }}
            </ion-text>
          </ion-item>
        </ion-list>
        <ion-list lines="full">
          <ion-item>
            <ion-label>입금대상목록 (총 {{ $filters.countTxt(pageInfo.total) }})</ion-label>
            <ion-icon
              :icon="funnelOutline"
              @click="presentActionSheet"
              slot="end" />
          </ion-item>
        </ion-list>
      </template>
      <template #contents="{ item }">
        <ion-label>
          <h2>{{ item.title }}</h2>
          <h3>{{ item.subTitle }}</h3>
          <p>{{ $filters.currencyWON(item.description) }}</p>
          <p>
            <vp-button
              @click="onClickConfirm(item)">
              입금확인
            </vp-button>
          </p>
        </ion-label>
      </template>
    </vp-template-list>
    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="onClickDepositTargetDownload()">
      입금대상목록 다운로드
    </vp-button>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { IonList, IonItem, IonLabel, IonText, IonIcon, toastController } from '@ionic/vue'
import { funnelOutline } from 'ionicons/icons'
import { ref, inject, onMounted } from 'vue'
import VpTemplateList from '@/components/templates/list/VpTemplateList.vue'
import { getOrders } from '@/api/purchase/purchase'
import { getToken } from '@/utils/auth'
import { editAcStateCode } from '@/api/dealer/Receipt'
import { useIonRouter, actionSheetController } from '@ionic/vue'
import { decrypt } from '@/utils/jsencrypt'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const fixed = ref(true)
const token = getToken()
const jwtDecode = inject('$jwtDecode')
const user = jwtDecode(token)
const ionRouter = useIonRouter()

const datas = ref([])

const pageInfo = ref({
  total: 0,
  pageNum: 1,
  pageSize: 99999
})
const cnt1 = ref(0)
const totalPrice = ref(0)

const getOrderList = async (query) => {
  const orders = await getOrders(query)
  orders.data.results.forEach((obj) => {
    const accStateTxt = Number(obj.acc_state_code) === 1 ? '입금전' : '입금완료'

    let  title = []
    if (obj.order_nm) {
      title.push(obj.order_nm)
    }
    if (obj.order_tel_no) {
      title.push(obj.order_tel_no)
    }
    if (accStateTxt) {
      title.push(accStateTxt)
    }

    let subTitle = []
    if (obj.acc_nm) {
      subTitle.push(obj.acc_nm)
    }
    if (obj.bank_nm) {
      subTitle.push(obj.bank_nm)
    }
    if (obj.acc_num) {
      subTitle.push(decrypt(obj.acc_num))
    }

    obj.title = title.join(' / ')
    obj.subTitle = subTitle.join(' / ')
    obj.description = Number(obj.grade1_price)
    
    cnt1.value = Number(orders.data.results.filter((item) => Number(item.acc_state_code) === 1).length)
    totalPrice.value += Number(obj.grade1_price)
  })

  if(orders.data.results.length === 0){
    cnt1.value = 0
    totalPrice.value = 0
  }

  datas.value = orders.data.results
  pageInfo.value.total = orders.data.count
}

//확인하기
const onClickConfirm = async(item) => {
  const params = ref({
    id: item.id, 
    acc_state_code: 2
  })
  const res = await editAcStateCode(params.value)
  if(res.code == '200'){
    await getOrderList(pageInfo.value)
  } else {
    const toast = await toastController.create({
    message: '오류가 발생하였습니다.',
    buttons: ['확인']
    })
    await toast.present()
  }
}

// 엑셀 다운로드
const onClickDepositTargetDownload = () => {
  ionRouter.push({name: 'depositTarget'})
}

////// 필터부분 추가 시작 - 임시 ///////
const presentActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: '선택한 접수상태 목록만 보여집니다.',
    //subHeader: '선택한 항목만 보여집니다',
    buttons: [
      
      {
        text: '입금전',
        data: {
          action: '1'
        }
      },
      {
        text: '입금완료',
        data: {
          action: '2'
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel'
        }
      }
    ]
  })

  await actionSheet.present()
  const res = await actionSheet.onDidDismiss()

  if(res.role != 'cancel') {
    const param = pageInfo
    param.value.acc_state_code = res.data.action
    await getOrderList(param.value)
  }

//  result.value = JSON.stringify(res, null, 2)
  
}

////// 필터부분 추가 끝 - 임시 ///////

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  await getOrderList(pageInfo.value)
})
</script>
